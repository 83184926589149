<template>
	<div class="">
		<div class="container-fluid text-center" style="margin:0 auto;padding:50px">

			<div class="bg-blue margint20 border-blue" style="padding:50px">
				<div>
					<!-- <img src="/static/images/travelmanagement/ccsqd/cc.png" alt=""> -->
					<span class="orange bigfont" style="color:#F5A337">支付成功</span>
				</div>
				<span class="orange bigfont" style="padding:10px 0 0 110px">
					<a ref="pay-success-time" style="color: red">5秒</a>后将跳转到订单页面……
					<a style="color:#6699FF" id="jump" @click="goToOrderDetail()">跳转</a>
				</span>
			</div>
		</div>
		<div v-show="isShowWenXinTips">
			<div>温馨提示：</div>
			<div class="wei-xin-content">
				{{ wenXinTips }}
			</div>
			<div class="wei-xin-item" v-for="(wenXinItem, index) in wenXinTipsList" :key="index">
				<a href="wenXinItem.url">{{ wenXinItem.name }}</a>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	name: "PaySuccess",
	data() {
		return {
			isShowWenXinTips: false,
			wenXinTips: '由于网上银行和支付系统只支持IE浏览器，在您支付时，浏览器自动帮您切换到兼容模式<br/>当您看到此提示时，请您手动将浏览器切换到极速模式！',
			wenXinTipsList: [{
				name: "什么是极速模式",
				content: "",
				url: "https://zhidao.baidu.com/question/415571135.html",
			}, {
				name: "我该如何切换",
				content: "",
				url: "https://kf.qq.com/info/44692_0.html",
			}],
			tiaoTimer: null, // 跳转定时器
			times: 5,
		}
	},
	mounted() {
		this.init();
	},
	destroyed() {
		this.tiaoTimer = null;
	},
	methods: {
		init() {

			/**
				 * 在线支付支付，页面回跳到obt，极有可能造成sessionStorage中token无法获取
				 * 因此在选择支付后，检测token
				 * */
			var token = this.$common.sessionget("access_token") || "";
			if (token == "" || token == "null" || token == "undefined" || token.length != 32) {
				token = common.localget("tmpaccess") || '';
			}


			if (!token) {
				this.isShowWenXinTips = true;
				return false;
			}

			this.$common.sessionset("access_token", token);

			// 通过交易号获取详情
			var params = this.$common.parseQueryString();

			// this.getOrderDetail(out_trade_no);
			var out_trade_no = params["out_trade_no"];
			if (out_trade_no != "") {
				this.getOrderDetail(out_trade_no);
			}

		},
		// 通过交易号获取详情
		getOrderDetail(payInfoId) {
			if (payInfoId) {
				var param = {
					payInfoId: payInfoId
				};

				this.$conn.getConn("pay.getPayOrderIdList")(param, (resp) => {
					let orderList = resp.data;
					this.timer(orderList);
				}, (err) => {

				});
			} else {
				this.timer();
			}
		},
		timer(orderList) {
			var self = this;
			var timeDom = self.$refs['pay-success-time'], sec = 5;
			// 清除计时器setInterval
			clearInterval(self.tiaoTimer)
			self.tiaoTimer = setInterval(function () {
				if (sec == 0) {
					clearInterval(self.tiaoTimer)
					if (orderList) {
						self.goToOrderDetail(orderList);
					} else {
						// 清除计时器setInterval 暂不确定需要删除这个clearInterval
						clearInterval(self.tiaoTimer)
						self.$router.replace({
							path: "/yjTravel/myTravel/userCenter/order",
						})
					}
				} else {
					sec--;
					timeDom.innerHTML = `${sec}秒`;

				}
			}, 1000);



		},
		// 跳转订单详情
		goToOrderDetail(orderList) {
			clearInterval(this.tiaoTimer)
			if (orderList && orderList.length == 1) {

				var order = orderList[0] || {};
				var orderId = order["orderId"];//订单id
				var orderType = order["orderType"];//订单类型
				let path = this.$dict.detailConfig(orderType)
				this.$router.replace({
					path: path,
					query: {
						orderId: orderId,
						orderType: orderType,
					}
				})

			} else {
				this.$router.replace({
					// path:"/yjtravel/myTravel/order",
					path: "/yjTravel/myTravel/userCenter/order"
				})
			}

		}



	}
}
</script>

<style class="scoped">
.wei-xin-content {
	color: red;
	font-size: 12px;
}

.wei-xin-item {
	height: 40px;
	line-height: 40px;
	font-size: 12px;
}
</style>
